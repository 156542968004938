export const projects = [
  {
    title: "Phantom Plates",
    subtitle: "Model View Controllers",
    description:
      "An ecommerce application designed to provide foodies with delicious homemade meals and homecooks with an outlet for their passion.",
    image: require("./images/phantom-plates.png"),
    link: "https://phantom-plates.herokuapp.com/",
  },
  {
    title: "Snake 2: 2 Snakes 2 Furious",
    subtitle: "First Frontend Project",
    description:
      "A snake game designed to generate snake facts with every high score via Wikipedia's web API.",
    image: require("./images/snake-game.gif"),
    link: "https://lorddanklin.github.io/snake-game/",
  },
  {
    title: "Couchy",
    subtitle: "Final Bootcamp Project",
    description:
      "A travel accomodation website that provides listings from the unlikliest of places. Where's my couch?",
    image: require("./images/couchy.png"),
    link: "https://the-real-couchy.herokuapp.com/",
  },
];

export const skills = [
  "JavaScript",
  "Node.js",
  "MySQL",
  "React",
  "Python",
  "Express.js",
  "MongoDB",
  "Progressive Web Applications",
  "CSS",
  "HTML",
  "Public Speaking",
  "Writing",
  "Communications",
];
